/* Modify the background color */
    
.navbar-custom {
    background-color: #426D49;
}
/* Modify brand and text color */
    
.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
    color: white;
}

.navbar-custom .nav-link {
    color: white
}